import React, { useState } from "react";
import ClientData from "../clientData";
import RButton from "../../component/rButton";
import RImg from "../../component/rImg";
import EditButton from "../../assets/email/editButton.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DigiLocker from "../digiLocker";
import LockedAccount from "../LockedAccount";
import Loader from "../../component/loader";
import useApi from "../../hooks/useApi";

const ClientIdLogin = (props) => {
  const [clientId, setClientId] = useState("");
  const [valid, setValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const { loading, error, postData } = useApi();

  const handleChange = (event) => {
    setClientId(event.target.value);
  };
  const handleSubmit = async (event, value) => {
    event?.preventDefault();
    // Perform validation
    const id = value ?? clientId;
    const regex = /^([a-zA-Z0-9_-]){3,9}$/;
    const isValid = regex.test(id);
    if (isValid) {
      const response = await props.handlePostRequest(
        { value: clientId },
        `rekyc/send/otp`
      );
      setValid(isValid);
      setErrorMsg("");
    } else {
      setErrorMsg("* Invalid Client Id");
    }
  };
  const handleEditNumber = () => {
    setValid(false);
  };
  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };
  const handleCancelOTP = () => {
    setValid(false);
    setOtp("");
    setOtpErrorMsg("");
  };
  const handleOtpSubmit = (event) => {
    event.preventDefault();
    if (otp.trim().length === 4) {
      props.handlePostRequest({ dpId: clientId, otp: otp }, `rekyc/verify/otp`);
    } else {
      setOtpErrorMsg("Invalid OTP");
    }
  };

  return (
    <>
      {/* {props.response.accountStatus === "Disabled" ? <DigiLocker /> : ""} */}
      {props?.response?.accountStatus ? (
        <ClientData data={props?.response} />
      ) : (
        <>
          <form className="flex justify-around items-center flex-wrap rounded-t-2xl p-3 bg-grey">
            <div className="sm:mb-4">
              <div className="email-container-content font-bold text-blue text-6xl max-[1087px]:text-5xl max-[998px]:text-4xl">
                <p>RE KYC your Nirman</p>
                <p>Demat Account in</p>
                <p>just 5 minutes</p>
                
                  <p className="note-main text-sm mt-4 text-black font-thin">NIRMAN :- “NIRMAN HOUSE” 8, Zone - 1, M. P. Nagar, Bhopal - 462011. (Ph :- 0755-43 11 111)</p>
              </div>
            </div>
            <div className="">
              <div className="rounded-xl text-black bg-red shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]">
                <div>
                  {!valid && (
                    <>
                      <div className="login-container p-9 max-[998px]:p-5">
                        <div className="mb-3">
                          <p className="right-content text-2xl text-white">
                            Enter Client ID
                          </p>
                        </div>
                        <div className="mb-2">
                          <input
                            autoFocus={true}
                            type="text"
                            name="clientId"
                            id="clientId"
                            value={clientId}
                            onChange={handleChange}
                            placeholder="Enter Client ID"
                            className="numberInput p-3 rounded-full spin-button-none text-black"
                          />
                        </div>
                        <p className="text-red mb-5">{errorMsg}</p>
                        <div className="flex">
                          <input type="checkbox" checked className="me-4" />
                          <div className="text-white text-sm max-[998px]:text-xs">
                            I agree to receive communication from Nirman
                            <br /> through SMS, Whatsapp, Email, and Calls.
                          </div>
                        </div>
                        <br />
                        <RButton
                          handleButtonClick={handleSubmit}
                          buttonName="Send otp"
                          bgColor="bg-navy text-white"
                          externalClassName="p-2"
                          type={"submit"}
                        />
                      </div>
                    </>
                  )}
                  {valid ? (
                    <>
                      <div className="p-9">
                        <p className="text-white text-2xl mb-1 font-semibold">
                          Enter the OTP sent on
                          <br />
                          to your mobile no. or email id
                        </p>
                        <div className="flex">
                          <p className="mb-3 opacity-70 text-white">
                            {clientId}
                          </p>
                          <div onClick={handleEditNumber}>
                            <RImg
                              innerImgClassName="w-[20px]"
                              externalClassName="ml-4"
                              src={EditButton}
                              alt="edit"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <input
                            autoFocus
                            type="number"
                            name="otp"
                            id="otp"
                            value={otp}
                            onChange={handleOtpChange}
                            placeholder="Enter otp"
                            className="text-black p-3 rounded-full spin-button-none "
                          />
                        </div>

                        <div className="mob-button flex justify-between mt-3">
                          <p
                            onClick={handleCancelOTP}
                            className="cursor-pointer text-white"
                          >
                            Cancel OTP
                          </p>
                          <p
                            onClick={handleSubmit}
                            className="cursor-pointer text-white"
                          >
                            Resend
                          </p>
                        </div>
                        <RButton
                          handleButtonClick={handleOtpSubmit}
                          buttonName="Continue"
                          bgColor="bg-tertiary"
                          externalClassName="p-2 mt-2 text-white bg-navy"
                          type={"submit"}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default ClientIdLogin;
