import React, { useState, useEffect } from "react";
import Logo from "../../assets/kyc/nirman_Logo.png";
import Loader from "../../component/loader"

function ClosureInfo() {
  const [loader, setLoader] = useState(false);
  const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse")) || {};
  const clientAddrssThird = storedResponse.clientAddressThree || "";
  const reason = JSON.parse(sessionStorage.getItem("reason")) || "";
  const [isEsign, setEsign] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [msg, setMsg] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showDpid, setShowDpid] = useState(false);
  const [showHolding, setShowHolding] = useState(true);
  const [payload, setPayload] = useState({
    cash: false,
    fAndO: false,
    bseCash: false,
    bseFAndO: false,
    bseCd: false,
    dpidCheck: false,
    dpSupportImage: "",
    dpId: storedResponse.clientCode,
    name: storedResponse.name,
    panNumber: storedResponse.panNumber,
    mobile: storedResponse.phoneNumber,
    email: storedResponse.email,
    address: `${storedResponse.clientAddressOne || ""} ${storedResponse.clientAddressTwo || ""} ${clientAddrssThird}`,
    city: storedResponse.clientAddressFour,
    pincode: storedResponse.pincode,
    state: storedResponse.state,
    newTransferClientCode: "",
    newTransferDpId: "",
    reasonForClosing: reason,
    clientOldDpId: storedResponse.userDpId,
  });

  useEffect(() => {
    openModal();
  }, []);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  function toggleEsign() {
    setPayload((prev) => ({
      ...prev,
      dpId: storedResponse.clientCode,
      name: storedResponse.name,
      panNumber: storedResponse.panNumber,
      mobile: storedResponse.phoneNumber,
      email: storedResponse.email,
      address: `${storedResponse.clientAddressOne || ""} ${storedResponse.clientAddressTwo || ""} ${clientAddrssThird}`,
      city: storedResponse.clientAddressFour,
      pincode: storedResponse.pincode,
      state: storedResponse.state,
      clientOldDpId: storedResponse.userDpId,
    }));
    setEsign((isEsign) => !isEsign);
  }

  const closeButton = () => {
    if (payload.newTransferDpId.length !== 16) {
      setMsg("DP ID must be exactly 16 digits");
      return;
    }

    const newTransferDpId = payload.newTransferDpId.substring(0, 8);
    const newTransferClientCode = payload.newTransferDpId.substring(8, 16);

    setPayload((prev) => ({
      ...prev,
      newTransferDpId,
      newTransferClientCode,
    }));

    if (payload.dpSupportImage === "") {
      setMsg("Please Upload Image");
      return;
    }

    setModalVisible(false);
    setShowCheckbox(true);
  };

  const handleShowDPID = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === "Transfer to another account") {
      setShowDpid(true);
    } else if (selectedOption === "I don't have any holding") {
      setModalVisible(false);
      setShowCheckbox(true);
    } else {
      setShowDpid(false);
    }
  };

  const EsignPopup = (event) => {
    return (
      <>
        {loader && <Loader open={loader} />}
        <div className="esign-wrapper "></div>
        <div className="esign-container bg-white p-6 rounded-xl flex justify-center">
          <button
            className="bg-navy text-white p-4 rounded-xl "
            onClick={handleSubmit}
          >
            Esign
          </button>
        </div>
      </>
    );
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setPayload((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleDpId = (e) => {};

  const handleSubmit = () => {
    // sessionStorage.clear()
    setPayload((prev) => ({
      ...prev,
      dpId: storedResponse.clientCode,
      name: storedResponse.name,
      panNumber: storedResponse.panNumber,
      mobile: storedResponse.phoneNumber,
      email: storedResponse.email,
      address: `${storedResponse.clientAddressOne || ""} ${storedResponse.clientAddressTwo || ""} ${clientAddrssThird}`,
      city: storedResponse.clientAddressFour,
      pincode: storedResponse.pincode,
      state: storedResponse.state,
      clientOldDpId: storedResponse.userDpId,
    }));
    // console.log(payload);
    setLoader(true)
    fetch(`https://ekyc.finovo.tech:5555/api/v1/closure/push/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setLoader(false)
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to send data to the API");
        }
      })
      .then((data) => {
        if (data) {
          window.location.href = data.message;
          sessionStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error sending data to the API:", error);
      });
  };

  const handleImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPayload((prev) => ({
          ...prev,
          dpSupportImage: reader.result,
        }));
        setShowImage(true);
      };

      reader.readAsDataURL(file);
    }
  };
  const userSegmentsList = (storedResponse.userSegmentsList) || [];

  return (
    <>
      {isEsign && <EsignPopup />}
      {/* {showHolding && <Holding />} */}
      <div>
        {modalVisible && (
          <div className="esign-wrapper">
            <div className="esign-container bg-white md:p-6 rounded-xl">
              <div className="flex flex-col my-2">
                <div className="text-center my-2">
                  <p className="font-bold">
                    Kindly clear your Holdings before close the account.
                  </p>
                </div>
                <select
                  onChange={handleShowDPID}
                  className="border p-3 rounded-t-xl"
                >
                  <option>select</option>
                  <option value="I don't have any holding">
                    I don't have any holding
                  </option>
                  <option value="Transfer to another account">
                    Transfer to another account
                  </option>
                </select>
                {showDpid && (
                  <>
                    <div className="flex justify-center">
                      <div className="font-bold flex items-center">
                        CDSL :-
                      </div>
                      <input
                        type="number"
                        className="border border-black p-2 rounded-xl m-2"
                        placeholder="Enter your DP ID"
                        name="newTransferDpId"
                        value={payload.newTransferDpId}
                        onChange={(e) =>
                          setPayload((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <p className="font-bold my-2 text-center">
                      Please upload your other DP client master
                    </p>
                    <div className="flex justify-center">
                      <input
                        type="file"
                        // accept="image/*"
                        className="overflow-hidden"
                        name="dpSupportImage"
                        onChange={handleImage}
                      />
                      {showImage && (
                        <img
                          width="100px"
                          className="border"
                          src={payload?.dpSupportImage}
                        />
                      )}
                    </div>
                  </>
                )}
                <p className="text-center text-red mt-2">{msg}</p>
                <button
                  className="my-2 bg-blue m-auto px-3 py-2 font-bold rounded-sm"
                  onClick={closeButton}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="closure-wrapp"></div>
      <div className="closure-contain bg-white p-6 rounded-xl">
        <div>
        <div className="bg-grey h-screen">
            {/* <div className="bg-grey w-full">
              <img src={Logo} width="100px" className="p-3" />
            </div> */}
            <div className="flex justify-center items-center">
              <div className="bg-white w-[80%] flex sm:flex-col md:flex-row shadow-xxl">
                <div className=" bg-blue opacity-70 border flex items-center justify-center">
                  <img
                    className="rounded-full w-[150px] mb-10"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7t6xWr4vE6oXTCasnOKbpgb7xOYLAOTjC5tTqrfmf0I9U2KLiIelKpHVldN5OBO1jJ4I&usqp=CAU"
                  />
                </div>
                <div className="w-full border">
                  <header className="text-start p-2 font-bold text-xl ">
                    Information
                    <hr />
                  </header>
                  <main className="p-2 text-start">
                    <div className="pt-4">
                      <div className="flex flex-col w-[50%]">
                        <p className="font-bold ">Name</p>
                        <span className="font-bold opacity-60">
                          {storedResponse?.name}
                        </span>
                      </div>
                      <div className="flex flex-col w-[50%]">
                        <p className="font-bold ">Client Code</p>
                        <span className="font-bold opacity-60">
                          {storedResponse?.clientCode}
                        </span>
                      </div>
                    </div>
                    <div className="pb-4">
                      <div className="flex flex-col w-[50%]">
                        <p className="font-bold ">Email</p>
                        <span className="font-bold opacity-60">
                          {storedResponse?.email}
                        </span>
                      </div>
                      <div className="flex flex-col w-[50%]">
                        <p className="font-bold ">Pan Number</p>
                        <span className="font-bold opacity-60">
                          {storedResponse?.panNumber}
                        </span>
                      </div>
                    </div>
                    {showCheckbox && (<div>
                      <p className="text-xl font-bold ">
                        Segments<span>(close)</span>
                        <hr className="my-2" />
                      </p>
                      <p className="flex items-center">
                        {userSegmentsList?.includes("NSE") && (
                          <>
                            <b>NSE</b> : Cash
                            <input
                              type="checkbox"
                              name="cash"
                              onChange={handleChange}
                              className="m-2"
                            />{" "}
                            | DPID
                            <input
                              type="checkbox"
                              name="dpidCheck"
                              onChange={handleChange}
                              className="m-2"
                            />{" "}
                          </>
                        )}
                        {userSegmentsList?.includes("NSEFO") && (
                          <>
                            {" "}
                            F&O
                            <input
                              type="checkbox"
                              name="fAndO"
                              onChange={handleChange}
                              className="m-2"
                            />{" "}
                            |
                          </>
                        )}
                        {userSegmentsList?.includes("BSE") && (
                          <>
                            {" "}
                            <b>BSE</b> : Cash
                            <input
                              type="checkbox"
                              name="bseCash"
                              onChange={handleChange}
                              className="m-2"
                            />{" "}
                            |
                          </>
                        )}
                        {userSegmentsList?.includes("BFO") && (
                          <>
                            {" "}
                            BFO
                            <input
                              type="checkbox"
                              name="bseFAndO"
                              onChange={handleChange}
                              className="m-2"
                            />
                          </>
                        )}
                        {userSegmentsList?.includes("BCD") && (
                          <>
                            {" "}
                            BCD
                            <input
                              type="checkbox"
                              name="bseCd"
                              onChange={handleChange}
                              className="m-2"
                            />
                          </>
                        )}
                      </p>
                    </div>)}
                  </main>
                  <div className="w-full flex justify-end p-2">
                    <button
                      onClick={toggleEsign}
                      className="p-2 bg-gradient-to-r from-blue via-blue to-navy pt-2 rounded-xl text-white border m-auto "
                    >
                      Proceed to close Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClosureInfo;
