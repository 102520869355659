import React from 'react'

const ThankYou = () => {
    const handleSubmit = () => {
        // props.handleKycStatus("CLIENT_ID")
        window.location.reload();
        sessionStorage.clear()
}

  return (
    <div className='w-full h-full z-10 modal-wrapper2 modal-container3 flex flex-col flex-wrap items-center justify-center text-2xl font-bold '>
      <div className="bg-white w-full h-[50vh] flex flex-col flex-wrap items-center justify-center">
            <p className='w-60% text-center '>We have taken your details, we will get back to you over the email.</p>
              <button className='m-4 bg-blue border rounded p-2'
              onClick={handleSubmit}
              >LOGOUT</button>
          </div>
    </div>
  )
}

export default ThankYou
