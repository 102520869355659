import "./App.css";
import React from "react";
import Rekyc from "./reKyc";

function App() {

  return (
    <Rekyc/>
  );
}

export default App;
