import React from "react";

export default function Relation(props) {
    const handleChange = (event) => {
        props.handleChange(event.target.name, event.target.value);
    }

    const options = [
        "Relation",
        "Spouse",
        "Son",
        "Daughter",
        "Father",
        "Mother",
        "Brother",
        "Sister",
        "Grand-Son",
        "Grand-Daughter",
        "Grand-Father",
        "Grand-Mother",
        "Not Provided",
        "Other",
    ];

    return (
        <>
            <div className="p-2 flex flex-col">
                <label htmlFor="relation" className="font-semibold">
                    Relation*
                </label>
                <select
                    id="relation"
                    name="relation"
                    value={props.nominee.relation}
                    onChange={handleChange}
                    className="mt-2 p-2 bg-white rounded-xl text-black"
                >
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}
