import React from "react";

export default function State(props) {
  const ADDRESS_FORMAT = [
    { key: "houseNumber", value: "Address" },
    { key: "street", value: "Street" },
    { key: "district", value: "City" },
    {
      key: "state", value: "State",
      options: [
        "ANDAMAN AND NICOBAR ISLANDS",
        "ANDHRA PRADESH",
        "ARUNACHAL PRADESH",
        "ASSAM",
        "BIHAR",
        "CHANDIGARH",
        "CHHATTISGARH",
        "DADRA AND NAGAR HAVELI",
        "DAMAN AND DIU",
        "DELHI",
        "GOA",
        "GUJARAT",
        "HARYANA",
        "HIMACHAL PRADESH",
        "JAMMU AND KASHMIR",
        "JHARKHAND",
        "KARNATAKA",
        "KERALA",
        "LAKSHADWEEP",
        "MADHYA PRADESH",
        "MAHARASHTRA",
        "MANIPUR",
        "MEGHALAYA",
        "MIZORAM",
        "NAGALAND",
        "ODISHA",
        "PUDUCHERRY",
        "PUNJAB",
        "RAJASTHAN",
        "SIKKIM",
        "TAMIL NADU",
        "TELANGANA",
        "TRIPURA",
        "UTTAR PRADESH",
        "UTTARAKHAND",
        "WEST BENGAL"
      ]
    },
    { key: "pincode", value: "Zip Code" },
    { key: "country", value: "Country" },
  ];

  const handleAddressChange = (event) => {
    props.handleAddressChange(
      "addressDto." + event.target.name,
      event.target.value
    );
  };

  const stateOptions = ADDRESS_FORMAT.find(field => field.key === "state").options;

  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="state" className="font-semibold">
          State*
        </label>
        <select
          id="state"
          name="state"
          value={props.nominee.addressDto.state}
          onChange={handleAddressChange}
          className="mt-2 p-2 bg-white rounded-xl text-black"
        >
          {stateOptions.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
