import * as React from "react";
import "react-toastify/dist/ReactToastify.css";
import Tabs from "@mui/joy/Tabs";
import CameraCapture from "../../component/captureImage";
import Modal from "@mui/material/Modal";
import SignatureCanvas from "react-signature-canvas";
import CameraInstruction from "../../assets/camera/cameraInstruction-1.png";
import RButton from "../../component/rButton";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import DigiLocker from "../digiLocker";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import useSessionStorage from "../../hooks/useSession";
import Relation from "../../component/relation";
import Name from "../../component/name";
import Email from "../../component/email";
import Mobile from "../../component/mobile";
import Dob from "../../component/dateOfBirth";
import SharePercentage from "../../component/sharePercentage";
import PoiReferenceNo from "../../component/poiReferenceNo";
import "./styles.css";
import IdentityProof from "../../component/identityProof";
import TypeOfDocument from "../../component/typeOfDocument";
import State from "../../component/state";
import Address1 from "../../component/address1";
import Address2 from "../../component/address2";
import District from "../../component/district";
import Pincode from "../../component/pincode";
import Country from "../../component/country";
import Loader from "../../component/loader";
import ThankYou from "../thankyouPage";

export default function TabsVertical(props) {
  const [addressButton, setAddressButton] = useState(true)
  const [request, setRequest] = useState(true)
  const [showComponent, setShowComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [optionYesChecked, setOptionYesChecked] = useState(false);
  const [optionNoChecked, setOptionNoChecked] = useState(false);
  const [isClicked, setIsClicked] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [showThankyou, setShowThankyou] = useState(true);
  const [showModalEmail, setShowModalEmail] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isChangeClicked, setIsChangeClicked] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [bankAcNo, setbankAcNo] = useState("");
  const [confirmBankAcNo, setConfirmBankAcNo] = useState('');
  const [accType, setAccType] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorIfscCode, setErrorIfscCode] = useState("");
  const [addressChange, setAddressChange] = useState(false);
  const [reKycStatus, setReKycStatus] = useSessionStorage("rekycStatus", "");
  const [ifsc, setIfsc] = useState("");
  // const [isClick, setIsClick] = useState(false);
  // const [newAddress, setNewAddress] = useState("");
  // const [loaded, setLoaded] = useState(false);
  // const [showModalAddress, setShowModalAddress] = useState(false);
  const [ver, setVer] = useState("");
  const [data, setData] = useState({});
  const [showModalBank, setShowModalBank] = useState(false);
  const [netWorth, setNetWorth] = useState("below 1 Lac");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [imageCapture, setImageCapture] = useState(false);

  const [digiSign, setDigiSign] = useState();
  const [url, setUrl] = useState();
  const [errorLocation, setErrorLocation] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [xUserId, setXUserId] = useSessionStorage("token", "");
  const [clientReactivation, setClientReactivation] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const storedRes = JSON.parse(sessionStorage.getItem("apiResponse"));
  const arr = storedRes.userSegmentsList;
  const [newSegmentList, setNewSegmentList] = useState(arr);
  const [duplicateNewSegmentList, setDuplicateNewSegmentList] = useState(arr);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDigi, setShowDigi] = useState(false);
  const [formData, setFormData] = useState({
    incomeRange: "",
    netWorth: "",
    image: "",
    nominee: "",
    signature: "",
    bankProof: "",
    financialProof: "",
    geolocation: { latitude: "", longitude: "" },
  });
  const [checkboxValues, setCheckboxValues] = useState({
    yes: false,
    no: false,
  });
  const [nominee, setNominee] = useState([
    {
      name: "",
      email: "",
      contactNumber: "",
      dateOfBirth: "",
      relation: "",
      addressDto: {
        houseNumber: "",
        location: "",
        street: "",
        votingCenter: "",
        district: "",
        pincode: "",
        state: "",
        country: "",
      },
      sharePercentage: "",
      proofOfAddress: "",
      poiReferenceNumber: "",
      typeOfDocument: "",
      // should be sent at object
      guardianIdProof: "",
      guardianName: "",
      guardianRelation: "",
      guardianAddress: "",
      guardianIdProofNumber: "",
    },
  ]);
  const Addressbutton = () => {
    const addressButton = JSON.parse(sessionStorage.getItem("apiResponse2"))
    if (!addressButton) {
      return false;
    } else {
      setAddressButton(false)
    }
  }
  const getLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setFormData((prev) => ({
            ...prev,
            geolocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          }));
        },
        (error) => {
          setErrorLocation(
            "* Please provide location permission as its necessary for KYC"
          );
        }
      );
    }
  };
  useEffect(() => {
    getLocation()
    Addressbutton()
  }, [])
  const ANNUAL_SALARY_DETAILS_CONSTANTS = [
    { key: "1", value: "Below 1 Lac" },
    { key: "2", value: "1-5 Lac" },
    { key: "3", value: "5-10 Lac" },
    { key: "4", value: "10-25 Lac" },
    { key: "5", value: "25-1 Cr" },
    { key: "6", value: ">1 Cr" },
  ];
  const handleCheckboxChangeInput = (event) => {
    const { name, checked } = event.target;

    setNewSegmentList((prevnewSegmentList) => {
      // Ensure prevnewSegmentList is an array and make a copy
      const newList = prevnewSegmentList ? [...prevnewSegmentList] : [];

      if (checked) {
        // Add the name to the list if it's not already present
        if (!newList.includes(name)) {
          newList.push(name);
        }
      } else {
        // Remove the name from the list if it's present
        const index = newList.indexOf(name);
        if (index !== -1) {
          newList.splice(index, 1);
        }
      }

      return newList;
    });
  }
  const activationData = JSON.parse(sessionStorage.getItem("apiResponse"));
  useEffect(() => {
    if (activationData && activationData.accountStatus === "Disabled") {
      setClientReactivation(true);
    } else {
      setClientReactivation(false);
    }
  }, [activationData]);
  const handleChange = (key, value, index) => {
    setNominee((prevNominees) => {
      const updatedNominees = [...prevNominees];
      updatedNominees[index] = {
        ...updatedNominees[index],
        [key]: value,
      };
      return updatedNominees;
    });
  };
  const validateSignatureFile = (file) => {
    if (!file) return true; // No file selected, no validation needed.

    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      setSignatureError("*Please select a PNG or JPG file for the signature.");
      return false;
    }

    setSignatureError(""); // Clear any previous error messages.
    return true;
  };
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];

    if (validateSignatureFile(file)) {
      getBase64(file, e.target.name);
    }
  };
  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormData((prev) => ({ ...prev, [name]: reader.result }));
    };
  };
  const cancelImage = (name) => {
    setFormData((prev) => ({ ...prev, [name]: "" }));
  };
  const handleCaptureImage = async (val) => {
    if (val && typeof val === "string" && val.startsWith("data:image/")) {
      const compressedImage = await compressImage(val);

      setFormData((prev) => ({ ...prev, image: compressedImage }));
    } else {
      setFormData((prev) => ({ ...prev, image: "" }));
      alert("Please capture a valid image or ensure your camera is working.");
    }
  };
  const compressImage = (imageDataUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 400;
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // Convert the compressed image to a data URL
        const compressedImage = canvas.toDataURL("image/jpeg");
        resolve(compressedImage);
      };
    });
  };
  const handleClear = () => {
    digiSign.clear();
    setUrl("");
  };
  const handleGenerate = () => {
    setOpen(false);
    setUrl(digiSign.getTrimmedCanvas().toDataURL("image/png"));
    setFormData((prev) => ({
      ...prev,
      signature: digiSign.getTrimmedCanvas().toDataURL("image/png"),
    }));
  };
  const handleMergeData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      newSegmentList: newSegmentList,
      nominee: nominee,
    }));
  };
  useEffect(() => {
    handleMergeData();
  }, [newSegmentList]);
  const handlePostData = async () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nominee: nominee,
    }));
    try {
      if (xUserId) {
        setXUserId(xUserId);
      }
      const response = await fetch(
        "https://ekyc.finovo.tech:5555/api/v1/submit/user/details",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            xuserid: xUserId,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Show the component on successful form submission
        setShowComponent(true);
      } else {
        toast.error("Failed to submit form data");
      }
    } catch (error) {
      toast.error("Error occurred while submitting form data:", error);
    }
  };
  const handleAccTypeChange = (event) => {
    setAccType(event.target.value);
  };
  const UploadPhoto = () => {
    return (
      <div className="bg-grey rounded-t-2xl p-16 max-[468px]:p-10">
        {errorLocation && (
          <div className="text-red text-center">{errorLocation}</div>
        )}

        <div className="flex justify-between flex-wrap text-white">
          <div className="text-white text-start">
            <p className="max-[880px]:text-xl text-white text-3xl mb-2">
              Upload Image
            </p>
            <p className="max-[880px]:text-lg text-white opacity-60 text-2xl my-4">
              Important Image Instructions
            </p>
            <ul className="max-[880px]:text-xs text-white text-start ml-4 list-disc">
              <li>Do not wear goggles,cap or anything covering the face</li>
              <li>Capture clear image with a sufficient light</li>
              <li>Not other person should appear in the image</li>
              <li>
                Distance between the camera should not be more than 0.5 meter
              </li>
              <li>Background of the image should be plan, preferable white</li>
              <li>
                Do not use the rotate image.Capture only an up-right image
              </li>
            </ul>
          </div>
          <div className="photo-div">
            <img className="w-full h-[25vh]" src={CameraInstruction} alt="" />
            <div className="mt-8">
              {!formData.image && (
                <RButton
                  buttonName="Enable Camera"
                  handleButtonClick={() => setImageCapture(true)}
                  bgColor="text-black"
                  color
                  border
                  text
                  externalClassName="bg-white"
                />
              )}
              {formData.image && (
                <>
                  <div>
                    <img
                      className="border-2 w-[250px] h-[200] rounded-lg"
                      src={formData.image}
                      alt=""
                    />
                  </div>
                  <div className="mt-2">
                    <button
                      className="text-white"
                      onClick={() => cancelImage("image")}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <Modal open={imageCapture} onClose={() => setImageCapture(false)}>
            <div className="flex justify-start">
              <CameraCapture
                handleCaptureImage={handleCaptureImage}
                imageValue={formData.image}
                onSave={() => setImageCapture(false)}
              />
            </div>
          </Modal>
        </div>
        <Modal
          open={open}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
        >
          <div className="flex justify-center items-center flex-col max-[480px]:mt-[150px]">
            <div className="pad-rotate">
              <SignatureCanvas
                canvasProps={{
                  width: 400,
                  height: 300,
                  className:
                    "sigCanvas border-2 border-grey rounded-lg bg-white overflow-hidden ",
                }}
                ref={(data) => setDigiSign(data)}
              />
            </div>
            <div>
              <button
                className="text-white"
                onClick={handleClear}
                sx={{ m: 4 }}
              >
                Clear
              </button>
              <button
                className="text-white"
                onClick={handleGenerate}
                sx={{ m: 4 }}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const handleIFSC = async () => {
    setLoad(true)
    try {
      setErrorIfscCode("");

      // First API call to check IFSC code validity
      const response = await fetch(`https://ifsc.razorpay.com/${ifsc}`);

      if (response.ok) {

        // IFSC code is valid, proceed with the second API call
        const addBankResponse = await fetch(
          "https://ekyc.finovo.tech:5555/api/v1/rekyc/bank/add",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              xuserid: xUserId,
            },
            body: JSON.stringify({
              accountNumber: bankAcNo,
              ifsc: ifsc,
              accountType: accType,
            }),
          }
        );

        // Assuming addBankResponse is the response from the second API call
        const responseBody = await addBankResponse.json();
        if (responseBody) {
          setLoad(false)
        }
        sessionStorage.setItem("pannyDrop",JSON.stringify(responseBody.verify)); // Assuming the response is JSON
        alert("Bank details verified and inserted successfully");
        setShowModalBank(false);
        // Check if the second API call was successful
        if (addBankResponse.ok) {
          // Display success message and show modal
          toast.success("Bank details added successfully!");
          setShowModalBank(false);
        } else {
          // Display error message for the second API call
          toast.error("Failed to add bank details. Please try again.");
        }
      } else {
        // IFSC code is invalid, display error messages
        setErrorIfscCode("* Invalid IFSC code");
        toast.error("Invalid IFSC code. Please check and try again.");
      }
    } catch (error) { }
  };
  const handleChangeBank = (event) => {
    event.preventDefault();
    // setbankAcNo(event.target.value);
    setbankAcNo(event.target.value);
    if (bankAcNo.trim().length <= 17 && bankAcNo.trim().length >= 4) {
      setErrorMsg("");
    } else {
      setErrorMsg("* Invalid Account no.");
    }
  };
  const handleIfscChange = (event) => {
    event.preventDefault();
    const newIfsc = event.target.value.trim().toUpperCase();
    setIfsc(newIfsc);

    if (newIfsc.length >= 8) {
      setErrorIfscCode("");
    } else {
      setErrorIfscCode("* Invalid IFSC");
    }
  };
  const handleFinalSubmit = async (event) => {
    event.preventDefault();

    if (confirmBankAcNo !== bankAcNo) {
      return setErrorMsg("Incorrect Bank account");
    }
    if (bankAcNo.trim().length <= 17 && bankAcNo.trim().length >= 4) {
      setErrorMsg("");
      if (ifsc.trim().length < 4) {
        setErrorIfscCode("* Invalid IFSC");
        toast.error("Invalid IFSC code. Please check and try again.");
      } else {
        await handleIFSC();
      }
    } else {
      setErrorMsg("Invalid Bank account");
    }
  };

  if (ver === true) {
    return null;
  }
  const renderMobile = () => {
    const handleChangePhoneNumber = () => {
      setIsChangeClicked(true);
      setShowModal(true)
    };

    const handleSendOtp = async () => {
      try {
        if (xUserId) {
          setXUserId(xUserId);
        }
        setLoading(true); // Move setLoading outside the try block
        const response = await fetch(
          "https://ekyc.finovo.tech:5555/api/v1/rekyc/update/send?type=SMS",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              xuserid: xUserId, // Include xuserid in the headers
            },
            body: JSON.stringify({
              value: newPhoneNumber,
            }),
          }
        );
        // Handle the response, maybe show a message to the user
      } catch (error) {
        toast.error("Error sending OTP:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    const handleVerifyOtp = async () => {
      setLoading(true); // Move setLoading outside the try block
      try {
        if (xUserId) {
          setXUserId(xUserId);
        }
        const response = await fetch(
          "https://ekyc.finovo.tech:5555/api/v1/rekyc/update/otp/verify?type=SMS",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              xuserid: xUserId, // Include xuserid in the headers
            },
            body: JSON.stringify({
              userKey: newPhoneNumber,
              otp,
            }),
          }
        );

        const data = await response.json();
        if (data.verified === true) {
          // OTP verification successful
          setIsOtpVerified(true);
          toast.success("Request send successfully");
        } else {
          toast.error("Otp in invalid ");
          setShowModal(true);
        }
      } catch (error) {
        toast.error("Error verifying OTP:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
    if (storedResponse) {
      const { phoneNumber } = storedResponse;
      return (
        <>
          <Loader open={loading} />
          <Loader open={load} />
          <div className="flex py-2 flex-col ">
            <p className=" flex text-start ">
              <span className="opacity-50 font-bold">Mobile No.</span>
              <span className="cursor-pointer">
                {isOtpVerified ? (
                  <>
                    <button className="text-black  ml-2 border p-1 rounded-xl bg-white font-bold px-4">(requested*)</button>
                  </>
                ) : (
                  <>
                    <button
                      className="text-black  ml-2 border p-1 rounded-xl bg-white font-bold px-4"
                      onClick={handleChangePhoneNumber}
                    >
                      Change
                    </button>
                  </>
                )}
              </span>
            </p>
            <span className="font-bold text-start ">{phoneNumber ?? ""}</span>
            {showModal && (
              <>
                <div className="flex justify-center">
                  {isChangeClicked && !isOtpVerified && (
                    <div className="modal-wrapper flex flex-col modal-container bg-white p-6 rounded-xl">
                      {/* Render the input for new phone number */}
                      <div className="flex justify-end">
                        <button
                          onClick={() => setShowModal(false)}
                          className="bg-black px-4 py-2 rounded-full"
                        >
                          X
                        </button>
                      </div>
                      <div className="flex justify-center">
                        <input
                          type="number"
                          placeholder="Enter New Phone Number"
                          value={newPhoneNumber}
                          onWheel={(e) => e.target.blur()}
                          className="text-black p-2 w-full my-4 border rounded-xl no-spinner"
                          onChange={(e) => setNewPhoneNumber(e.target.value)}
                        />
                      </div>
                      <button
                        onClick={handleSendOtp}
                        className="text-black bg-blue m-2 p-2"
                      >
                        Submit
                      </button>
                    </div>
                  )}

                  {newPhoneNumber && !isOtpVerified && (
                    <div className="modal-container2 bg-white p-6 rounded-xl">
                      {/* Render the input for OTP */}
                      <div>
                        <input
                          type="number"
                          placeholder="Enter OTP"
                          value={otp}
                          onWheel={(e) => e.target.blur()}
                          className="text-black text-start p-2 m-2 w-full border rounded-xl no-spinner"
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                      <div className="flex justify-center">
                        <button
                          onClick={handleVerifyOtp}
                          className="text-white bg-blue p-2 m-2 w-full"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      );
    }
  };
  const renderEmail = () => {
    const handleChangeEmail = () => {
      setIsClicked(true);
      setShowModalEmail(true)
    };

    const handleSendVerificationCode = async () => {
      try {
        // Common logic to set xUserId if needed
        if (xUserId) {
          setXUserId(xUserId);
        }

        setLoad(true);
        const response = await fetch(
          "https://ekyc.finovo.tech:5555/api/v1/rekyc/update/send?type=EMAIL",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              xuserid: xUserId,
            },
            body: JSON.stringify({
              value: newEmail,
            }),
          }
        );
        // Handle the response, maybe show a message to the user
      } catch (error) {
        toast.error("Error sending verification code:", error);
      } finally {
        setLoad(false); // Set loading to false regardless of success or failure
      }
    };

    const handleVerifyEmail = async () => {
      setLoad(true);
      try {
        // Common logic to set xUserId if needed
        if (xUserId) {
          setXUserId(xUserId);
        }
        const otp = verificationCode;
        const response = await fetch(
          "https://ekyc.finovo.tech:5555/api/v1/rekyc/update/otp/verify?type=EMAIL",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              xuserid: xUserId,
            },
            body: JSON.stringify({
              userKey: newEmail,
              otp,
            }),
          }
        );

        // Handle the response
        const data = await response.json();
        if (data.verified === true) {
          // OTP verification successful
          setIsEmailVerified(true);
          toast.success("Request send successfully");
          // Update the status to "requested*"
          // You need to implement your logic here to update the status
        } else {
          // OTP verification failed, show an error message
          toast.error("Otp in invalid ");
          setShowModalEmail(true);
        }
      } catch (error) {
        toast.error("Error verifying OTP:", error);
      } finally {
        setLoad(false); // Set loading to false regardless of success or failure
      }
    };
    const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
    if (storedResponse) {
      const { email } = storedResponse;

      return (
        <>
          <Loader open={load} />
          <div className="flex py-2 flex-col">
            <p className="text-start  flex">
              <span className=" font-bold  opacity-50">Email</span>
              <span className="cursor-pointer">
                {isEmailVerified ? (
                  <>
                    <button className="text-black  ml-2 border p-1 rounded-xl bg-white font-bold px-4">Requested</button>
                  </>
                ) : (
                  <>
                    <button className="text-black  ml-2 border p-1 rounded-xl bg-white font-bold px-4" onClick={handleChangeEmail}>
                      Change*
                    </button>
                  </>
                )}
              </span>
            </p>
            <span className="font-bold">{email ?? ""}</span>
            {showModalEmail && (
              <>

                <div className="flex justify-center">
                  {isClicked && !isEmailVerified && (
                    <div className="modal-wrapper modal-container flex flex-col bg-white p-6 rounded-xl">
                      {/* Render the input for new email */}
                      <div className="flex justify-end">
                        <button
                          onClick={() => setShowModalEmail(false)}
                          className="bg-black px-4 py-2 rounded-full"
                        >
                          X
                        </button>
                      </div>
                      <div className="flex justify-center">
                        <input
                          type="email"
                          placeholder="Enter New Email"
                          value={newEmail}
                          className="text-black p-2 w-full my-4 border rounded-xl"
                          onChange={(e) => setNewEmail(e.target.value)}
                        />
                      </div>
                      <button
                        onClick={handleSendVerificationCode}
                        className="text-black bg-blue m-2 p-2"
                      >
                        Submit
                      </button>
                    </div>
                  )}

                  {newEmail && !isEmailVerified && (
                    <div className="modal-container2 bg-white p-6 rounded-xl">
                      {/* Render the input for verification code */}
                      <div className="">
                        <input
                          type="text"
                          placeholder="Enter Verification Code"
                          value={verificationCode}
                          className="text-black text-start p-2 m-2 w-full border rounded-xl"
                          onChange={(e) => setVerificationCode(e.target.value)}
                        />
                      </div>
                      <div className="flex justify-center">
                        <button
                          onClick={handleVerifyEmail}
                          className="text-white bg-blue p-2 m-2 w-full"
                        >
                          Verify Email
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      );
    }
  };
  const handleDigi = () => {
    // setShowDigi(true);
    setReKycStatus("DIGILOCKER");
    window.location.reload();
  };

  const renderAddress = () => {
    const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
    if (storedResponse) {
      const { clientAddressOne, clientAddressTwo, cliendAddressThree, clientAddressFour, pincode } = storedResponse;
      return (
        <>
          <div className="flex py-2 flex-col">
            <div className="flex relative">
              <p className="font-bold opacity-50">Address</p>
              <div className="flex cursor-pointer">
                {addressButton ? (
                  <>
                    <input
                      type="checkbox"
                      className="ml-2 absolute top-[7px] w-36 bg-red z-10"
                      checked={addressChange}
                      onChange={(e) => setAddressChange(e.target.checked)}
                      onClick={handleDigi}
                    />
                    <span className="absolute z-5">(change*)</span>
                  </>
                ) : (<>
                  <button
                    className="text-black  ml-2 border p-1 rounded-xl bg-white font-bold px-4 cursor-pointer"
                  // onClick={renderRequest}
                  >
                    (Details inserted successfully*)
                  </button>
                </>)}

              </div>
            </div>
            <p className="flex font-bold flex-wrap">
              {/* {`${clientAddressOne} -  ${pincode}` ?? ""} */}
              {`${clientAddressOne === undefined ? "(Details inserted successfully*)" : clientAddressOne} ${clientAddressTwo === undefined ? "" : clientAddressTwo}${cliendAddressThree === undefined ? "" : cliendAddressThree}${clientAddressFour === undefined ? "" : clientAddressFour} ${pincode === undefined ? "" : - pincode}`}
            </p>
          </div>
          {/* {showDigi && <DigiLocker />} */}
        </>
      );
    }
  };

  const renderPersonal = () => {
    const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
    if (storedResponse) {
      const {
        dateOfBirth,
        panNumber,
        gender,
        fatherName,
        clientAddressOne,
        pincode,
      } = storedResponse;

      return (
        <div className="text-white flex flex-col">
          <div className="flex justify-start m-2 text-2xl font-semi-bold">
            Personal
          </div>
          <hr />
          <div className="border bg-white m-2 p-2 text-start rounded">
            <p className="text-black opacity-50 font-bold max-[780px]:text-[11px]">
              Updating e- mail and phone number will take up to 48 hours to
              reflect( awaiting to updation on exchanges depositories )
            </p>
          </div>
          <div className="flex sm:flex-col md:flex-row md:justify-between text-start">
            <div>
              <div className="flex py-2 flex-col">
                <p className="font-bold opacity-50">Date Of Birth</p>
                <p className="flex font-bold flex-wrap text-start">
                  {dateOfBirth}
                </p>
              </div>
              <div className="flex py-2 flex-col">{renderEmail()}</div>
              {/* <div className="flex py-2 flex-col">
                <p className="font-bold">Mother's Name</p>
                <span>{name ?? ""}</span>
              </div> */}
              <div className="flex py-2 flex-col">
                {/* <div className="flex">
                  {" "}
                  <p className="font-bold opacity-50">Address</p>{" "}
                  <input
                    type="checkbox"
                    className="ml-2"
                    checked={addressChange}
                    onChange={(e) => setAddressChange(e.target.checked)}
                  />
                </div>
                <p className="flex font-bold flex-wrap">
                  {`${clientAddressOne} -  ${pincode}` ?? ""}
                </p> */}
                {renderAddress()}
              </div>
              <div className="flex py-2 flex-col">
                <p className="font-bold opacity-50">PAN</p>
                <p className="flex font-bold flex-wrap text-start">
                  {panNumber ?? ""}
                </p>
              </div>
            </div>
            <div className="md:w-[50%]">
              <div className="flex py-2 flex-col">
                <p className="font-bold opacity-50">Gender</p>
                <p className="flex font-bold flex-wrap">{gender ?? ""}</p>
              </div>
              <div className="flex py-2 flex-col">{renderMobile()}</div>
              <div className="flex py-2 flex-col">
                <p className="font-bold opacity-50">Father's Name</p>
                <p className="flex font-bold flex-wrap">{fatherName ?? ""}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      isNewBankDefault: e.target.checked, // Update isNewBankDefault based on checkbox state
    });
  };
  const handleChangeConfirmBank = (event) => {
    setConfirmBankAcNo(event.target.value);
    if (errorMsg) {
      setErrorMsg('');
    }
  }
  const renderBank = () => {

    const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
    if (storedResponse) {
      const { bankDetailsOfUser } = storedResponse;
      const bankData = bankDetailsOfUser ?? [];
      const firstBankItem = bankData;
      const renderRequest = () => {
        setShowModalBank(true)
        setRequest(false)
      }
      return (
        <>
          <div className="flex py-3">
            <p className="text-white text-2xl font-bold mr-2">Bank</p>


            {request ? (<>
              <button
                className="text-black  ml-2 border p-1 rounded-xl bg-white font-bold px-4 cursor-pointer"
                onClick={renderRequest}
              >
                Add Bank
              </button>
            </>) : (<>
              <button
                className="text-black  ml-2 border p-1 rounded-xl bg-white font-bold px-4 cursor-pointer"
              // onClick={renderRequest}
              >
                Requested
              </button>
            </>)}

            <div className="flex px-2">
              {/* <p
                className={`text-white opacity-50 self-end ${
                  data.verify === false ? "" : "block"
                }`}
              >
                *Change
              </p> */}
            </div>
          </div>
          <hr className="text-white" />
          <div className="flex max-[780px]:flex-col">
            <div className="flex  text-white md:w-1/3 flex-col justify-start items-start">
              <div className="flex py-2 flex-col">
                <p className="font-bold opacity-50">Account Number</p>
                <span className="text-start">
                  {firstBankItem?.accountNumber ?? ""}
                </span>
              </div>
              <div className="flex py-2 flex-col">
                <p className="font-bold  opacity-50 ">Account Type</p>
                <span className="text-start">
                  {firstBankItem?.accountType ?? ""}
                </span>
              </div>
              <div className="flex py-2 flex-col">
                <p className="font-bold  opacity-50">Account IFSC</p>
                <span className="text-start">{firstBankItem?.ifsc ?? ""}</span>
              </div>
            </div>
            <div className="flex-col">
              <div className="py-2">
                <input
                  type="checkbox"
                  id="markAsDefaultBank"
                  onChange={handleCheckboxChange}
                  checked={formData.isNewBankDefault}
                />{" "}
                <label htmlFor="markAsDefaultBank" className="text-white">
                  Mark as Default
                </label>
              </div>
              <div className="text-start">
                <p className="text-white opacity-50 font-bold">
                  Account Number
                </p>
                <p className="text-start text-white mb-2">{bankAcNo}</p>
                <p className="text-white opacity-50 font-bold">Account Type</p>
                <p className="text-start text-white mb-2">{accType}</p>
                <p className="text-white opacity-50 font-bold"> Account IFSC</p>
                <p className="text-start text-white mb-2">{ifsc}</p>

                <p></p>
              </div>
              {showModalBank && (
                <>
                  <div className="modal-wrapper "></div>
                  <div className="modal-container bg-white p-6 rounded-xl">
                    <button
                      onClick={() => setShowModalBank(false)}
                      className="font-bold   flex justify-end w-full ml-3"
                    >
                      <p className="bg-blue rounded-full p-3">X</p>
                    </button>
                    <div
                      className={`p-5 ${data.verify === true ? "hidden" : ""}`}
                    >
                      <div className="detailsInput mr-12">
                        <label className="">Account No.</label>
                        <br />
                        <input
                          className="p-2 rounded-lg"
                          style={{
                            border: errorMsg
                              ? "1px solid red"
                              : "1px solid #ccc",
                          }}
                          type="number"
                          id="bankAcNo"
                          name="bankAcNo"
                          placeholder="Enter your account no"
                          autoComplete="bankAcNo"
                          value={bankAcNo}
                          onChange={handleChangeBank}
                          required
                        />
                        <p className="text-red">{errorMsg}</p>
                        <label htmlFor="confirmBankAcNo" className="">Confirm Account No.</label>
                        <br />
                        <input
                          className="p-2 rounded-lg"
                          style={{ border: errorMsg ? "1px solid red" : "1px solid #ccc" }}
                          type="number"
                          id="confirmBankAcNo" // Make sure the ID is unique
                          name="confirmBankAcNo" // Update the name for clarity
                          placeholder="Confirm your account no"
                          autoComplete="off" // Update autocomplete for security reasons
                          value={confirmBankAcNo}
                          onChange={handleChangeConfirmBank}
                          required
                        />
                        <p className="text-red">{errorMsg}</p>
                      </div>
                      <div className="detailsInput mr-12">
                        <label className="">Account Type</label>
                        <br />
                        <select
                          name="account"
                          id="account-select"
                          className="border"
                          onChange={handleAccTypeChange}
                        >
                          <option value="">--Please choose an option--</option>
                          <option value="SAVING">Saving</option>
                          <option value="CURRENT">Current</option>
                        </select>
                      </div>
                      {/* <div>
                        <label className="">Relation</label>
                        <input
                          style={{
                            border: "1px solid black",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100%",
                            padding: "0.5rem",
                          }}
                          type="text"
                          id="ifsc"
                          name="IFSC"
                          placeholder="Enter Relation"
                          value={ifsc}
                          onChange={handleIfscChange}
                          required
                        />
                      </div> */}
                      <div>
                        <label className="">IFSC Code</label>
                        <input
                          style={{
                            border: "1px solid black",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100%",
                            padding: "0.5rem",
                            // border:
                            //   error || errorIfscCode
                            //     ? "1px solid red"
                            //     : "1px solid #ccc",
                          }}
                          type="text"
                          id="ifsc"
                          name="IFSC"
                          placeholder="Enter IFSC Code"
                          value={ifsc}
                          onChange={handleIfscChange}
                          required
                        />
                        {/* <p className="text-red">{error || errorIfscCode}</p> */}
                      </div>
                      <div className="mt-10">
                        <button
                          onClick={handleFinalSubmit}
                          className="bg-blue font-bold p-3 rounded-lg"
                        >
                          Validate
                        </button>
                        <div className="text-white">{ver}</div>
                      </div>
                      {/* <ToastContainer /> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };
  const renderNominee = () => {
    const handleShowNominee = () => {
      if (optionYesChecked) {
        return true;
      }
      return false;
    };
    const handleAddressChange = (key, value, index) => {
      if (key.includes(".")) {
        const keys = key.split(".");
        setNominee((prevNominees) => {
          const updatedNominees = [...prevNominees];
          updatedNominees[index] = {
            ...updatedNominees[index],
            [keys[0]]: {
              ...updatedNominees[index][keys[0]],
              [keys[1]]: value,
            },
          };
          return updatedNominees;
        });
      } else {
        setNominee((prevNominees) => {
          const updatedNominees = [...prevNominees];
          updatedNominees[index] = {
            ...updatedNominees[index],
            [key]: value,
          };
          return updatedNominees;
        });
      }
      return console.log("vsjfhvgshvskjv");
    };

    const handleAddMoreClick = () => {
      if (nominee.length < 3) {
        setNominee((prevNominees) => [
          ...prevNominees,
          {
            name: "",
            email: "",
            contactNumber: "",
            dateOfBirth: "",
            relation: "",
            addressDto: {
              houseNumber: "",
              location: "",
              street: "",
              votingCenter: "",
              district: "",
              pincode: "",
              state: "",
              country: "",
            },
            sharePercentage: "",
            proofOfAddress: "",
          },
        ]);
      }
    };
    const handleSubtractClick = () => {
      if (nominee.length > 1) {
        setNominee((prevNominees) => prevNominees.slice(0, -1));
      }
    };

    return (
      <>
        <div>
          {handleShowNominee() && (
            <>
              {nominee &&
                nominee.map((nominee, index) => (
                  <div key={index}>
                    <div className="font-bold flex justify-between py-3 text-white text-2xl text-start">
                      <p>{`Nominee ${index + 1}`}</p>
                      <button
                        className="sm:bg-red md:bg-navy m-2 rounded-full py-2 px-4 text-xl text-white "
                        onClick={handleSubtractClick}
                      >
                        X
                      </button>
                    </div>
                    <hr className="text-white" />
                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4 text-white">
                      <Name
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Email
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Mobile
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Dob
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Relation
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Address1
                        handleAddressChange={(key, value) =>
                          handleAddressChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Address2
                        handleAddressChange={(key, value) =>
                          handleAddressChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <District
                        handleAddressChange={(key, value) =>
                          handleAddressChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <State
                        handleAddressChange={(key, value) =>
                          handleAddressChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Pincode
                        handleAddressChange={(key, value) =>
                          handleAddressChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <Country
                        handleAddressChange={(key, value) =>
                          handleAddressChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <SharePercentage
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 text-white">
                      <TypeOfDocument
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <PoiReferenceNo
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                      <IdentityProof
                        handleChange={(key, value) =>
                          handleChange(key, value, index)
                        }
                        nominee={nominee}
                      />
                    </div>
                  </div>
                ))}
              {nominee.length === 3 ? null : (
                <button
                  className="sm:bg-red md:bg-navy m-2 rounded-full py-2 px-4 text-xl text-white "
                  onClick={handleAddMoreClick}
                >
                  Add Nominee
                </button>
              )}
            </>
          )}
          <div className="flex justify-between ">
            <Button
              // variant="contained"
              // color="info"
              sx={{
                border: "1px solid white",
                backgroundColor: "#071945",
                borderRadius: "24px",
                color: "white",
                "&:hover": {
                  color: "white",
                },
                "@media (max-width: 780px)": {
                  // display: 'none',
                  backgroundColor: "#C30000",
                  border: "1px solid white",
                },
              }}
              onClick={() => handleTabNext(2)}
            >
              Previous
            </Button>
            <Button
              // variant="contained"
              // color="success"
              sx={{
                border: "1px solid white",
                backgroundColor: "#071945",
                borderRadius: "24px",
                color: "white",
                padding: "8px 12px",
                "&:hover": {},
                "@media (max-width: 780px)": {
                  // display: 'none',
                  backgroundColor: "#C30000",
                  border: "1px solid white",
                },
              }}
              onClick={() => handleNomineeTabNext(4)}
            >
              Next
            </Button>
          </div>
        </div>
      </>
    );
  };
  const renderOthers = (event) => {
    const handleYesCheckboxChange = (event) => {
      setOptionYesChecked(true);
      setOptionNoChecked(false);
      setCheckboxValues({
        ...checkboxValues,
        [event.target.name]: event.target.checked,
      });
    };
    const handleNoCheckboxChange = (event) => {
      setOptionYesChecked(false);
      setOptionNoChecked(true);
      setCheckboxValues({
        ...checkboxValues,
        [event.target.name]: event.target.checked,
      });
    };

    const handleChange = (e) => {
      const { name, value } = e.target;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

    return (
      <>
        <div className="text-white">
          <div className="flex justify-start m-2 text-2xl font-semi-bold">
            Others
          </div>
          <hr />
          <div className="flex flex-wrap">
            <div className="flex justify-start items-center">
              <p>Segment</p>
            </div>
            <div className="flex items-center font-bold py-2">
              <p className="font-bold px-6">NSE :-</p>
              <div className="flex justify-start gap-6">
                <div>
                  <input
                    type="checkbox"
                    name="NSE"
                    className="mr-2"
                    id="cash"
                    checked={newSegmentList && newSegmentList.includes("NSE")}
                    disabled={duplicateNewSegmentList && duplicateNewSegmentList.includes("NSE")}
                    onChange={handleCheckboxChangeInput}
                  />
                  <lable htmlFor="cash">Cash</lable>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="NSEFO"
                    id="fo"
                    className="mr-2"
                    checked={newSegmentList && newSegmentList.includes("NSEFO")}
                    disabled={duplicateNewSegmentList && duplicateNewSegmentList.includes("NSEFO")}
                    onChange={handleCheckboxChangeInput}
                  />
                  <lable htmlFor="fo">F&O</lable>
                </div>
              </div>
            </div>
            <div className="flex items-center font-bold py-2">
              <p className="font-bold px-6">BSE :-</p>
              <div className="flex justify-start gap-6">
                <div>
                  <input
                    type="checkbox"
                    className="mr-2"
                    name="BSE"
                    id="cash"
                    checked={newSegmentList && newSegmentList.includes("BSE")}
                    disabled={duplicateNewSegmentList && duplicateNewSegmentList.includes("BSE")}
                    onChange={handleCheckboxChangeInput}
                  />
                  <lable htmlFor="cash">Cash</lable>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="BFO"
                    id="fo"
                    className="mr-2"
                    checked={newSegmentList && newSegmentList.includes("BFO")}
                    disabled={duplicateNewSegmentList && duplicateNewSegmentList.includes("BFO")}
                    onChange={handleCheckboxChangeInput}
                  />
                  <lable htmlFor="fo">F&O</lable>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="BCD"
                    className="mr-2"
                    id="cd"
                    checked={newSegmentList && newSegmentList.includes("BCD")}
                    disabled={duplicateNewSegmentList && duplicateNewSegmentList.includes("BCD")}
                    onChange={handleCheckboxChangeInput}
                  />
                  <lable htmlFor="cd">CDS</lable>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap md:gap-10">
            <div className=" flex justify-center flex-col">
              <p>
                Net Worth as on date<span className="text-red">*</span>
              </p>
            </div>
            <div className="py-6">
              <label>
                <select
                  className="w-56 text-white sm:bg-navy md:bg-red border border-white rounded-xl p-3"
                  value={props.formData?.netWorth}
                  name="netWorth"
                  onChange={handleChange}
                >
                  {ANNUAL_SALARY_DETAILS_CONSTANTS.map((item) => (
                    <option
                      className="text-black bg-white rounded-xl p-2"
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
          <div className="flex flex-wrap md:gap-10">
            <div className=" flex justify-center flex-col">
              <p>
                Annual Income as on date<span className="text-red">*</span>
              </p>
            </div>
            <div className="py-6">
              <label>
                <select
                  className="w-56 text-white sm:bg-navy md:bg-red border border-white rounded-xl p-3"
                  value={props.formData?.incomeRange}
                  name="incomeRange"
                  onChange={handleChange}
                >
                  {ANNUAL_SALARY_DETAILS_CONSTANTS.map((item) => (
                    <option
                      className="text-black bg-white rounded-xl p-2"
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
          <div className="mb-3 flex flex-col">
            <p className="text-start">Do you want to add Nominee</p>
            <div className="">
              <div className="flex justify-start gap-6 px-6">
                <div>
                  <input
                    type="checkbox"
                    checked={optionYesChecked}
                    onChange={handleYesCheckboxChange}
                    className="mr-2"
                    id="yes"
                    name="yes"
                  />
                  <lable htmlFor="yes">Yes</lable>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="no"
                    id="no"
                    checked={optionNoChecked}
                    onChange={handleNoCheckboxChange}
                    className="mr-2"
                  />
                  <lable htmlFor="no">No</lable>
                </div>
              </div>
            </div>
          </div>
          <div className="font-bold text-start">
            My Sole country of tax residency India
          </div>
          <div className="flex mt-3 mb-6">
            <div className="flex justify-start flex-col m-2">
              <input type="checkbox" checked id="cd" />
            </div>
            <p className="text-start max-[780px]:text-sm">
              I have understood the information requirements of this Form (read
              along with FARCH & CRS Instrucrions) amd hereby confirm that the
              information provided by me/us on this form is true, correct and
              complete. I also confirm that I have read and understood the FATCA
              & terms and conditions below and conditons below and hereby accept
              the same. Name for your account will be update as per the income
              Tax database due to Exchange regulations.{" "}
            </p>
          </div>
        </div>
      </>
    );
  };
  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const renderDocuments = () => {
    const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };

    const handleFinancialChange = async (event) => {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

      const isFileSelected = !!file;

      // Update state to reflect file selection
      setFormData((prevFormData) => ({
        ...prevFormData,
        isFinancialProofImage: isFileSelected,
      }));

      if (isFileSelected) {
        if (allowedTypes.includes(file.type)) {
          setSelectedFile(file);
          const base64String = await convertFileToBase64(file);

          // Update state with base64 data
          let financialProof;
          if (file.type.startsWith('image/')) {
            financialProof = `data:${file.type};base64,${base64String}`;
          } else {
            financialProof = base64String;
          }

          setFormData((prevFormData) => ({
            ...prevFormData,
            financialProof: financialProof,
          }));
        } else {
          toast.error("Please select a valid image file (jpg, png, jpeg) or PDF.");
          event.target.value = null; // Clear the file input
          setSelectedFile(null);
          setFormData((prevFormData) => ({
            ...prevFormData,
            financialProof: "",
          }));
        }
      }
    };

    const handleBankChange = async (event) => {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

      const isFileSelected = !!file;

      // Update state to reflect file selection
      setFormData((prevFormData) => ({
        ...prevFormData,
        isBankProofImage: isFileSelected,
      }));

      if (isFileSelected) {
        if (allowedTypes.includes(file.type)) {
          setSelectedFile(file);
          const base64String = await convertFileToBase64(file);

          // Update state with base64 data
          let bankProof;
          if (file.type.startsWith('image/')) {
            bankProof = `data:${file.type};base64,${base64String}`;
          } else {
            bankProof = base64String;
          }

          setFormData((prevFormData) => ({
            ...prevFormData,
            bankProof: bankProof,
          }));
        } else {
          toast.error("Please select a valid image file (jpg, png, jpeg) or PDF.");
          event.target.value = null;
          setSelectedFile(null);
          setFormData((prevFormData) => ({
            ...prevFormData,
            bankProof: "",
          }));
        }
      }
    };

    const handleSignautureChange = async (event) => {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

      const isFileSelected = !!file;

      // Update state to reflect file selection
      setFormData((prevFormData) => ({
        ...prevFormData,
        isSignatureProofImage: isFileSelected,
      }));

      if (isFileSelected) {
        if (allowedTypes.includes(file.type)) {
          setSelectedFile(file);
          const base64String = await convertFileToBase64(file);

          // Update state with base64 data
          let signature;
          if (file.type.startsWith('image/')) {
            signature = `data:${file.type};base64,${base64String}`;
          } else {
            signature = base64String;
          }

          setFormData((prevFormData) => ({
            ...prevFormData,
            signature: signature,
          }));
        } else {
          toast.error("Please select a valid image file (jpg, png, jpeg) or PDF.");
          event.target.value = null; // Clear the file input
          setSelectedFile(null);
          setFormData((prevFormData) => ({
            ...prevFormData,
            signature: "",
          }));
        }
      }
    };

    const renderBankProof = () => {
      const storedResponse = sessionStorage.getItem("pannyDrop");
      if (storedResponse === "false") {
        return (
          <>
            <div className="flex justify-between">
              <div className="md:flex flex-col">
                <p>Bank Proof</p> <p>(jpg, png, jpeg, pdf)</p>{" "}
              </div>
              <div className="">
                <input
                  type="file"
                  accept="image/*,.pdf"
                  onChange={handleBankChange}
                />
              </div>
            </div>
          </>
        );
      }
    };

    const renderFinancialProof = () => {
      const areArraysEqual =
        JSON.stringify(newSegmentList) ===
        JSON.stringify(duplicateNewSegmentList);
      if (!areArraysEqual || clientReactivation) {
        return (
          <>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p className="text-white">Financial Proof</p>
                <p className="text-white">(jpg, png, jpeg, pdf)</p>
              </div>
              <div>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  onChange={handleFinancialChange}
                />
              </div>
            </div>
          </>
        );
      }
    };

    const renderPhoto = () => {
      const areArraysEqual =
        JSON.stringify(newSegmentList) ===
        JSON.stringify(duplicateNewSegmentList);
      if (!areArraysEqual || clientReactivation || addressChange === true) {
        return (
          <>
            <UploadPhoto handlePostData={props.handlePostData} />
          </>
        );
      }
    };

    const renderSign = () => {
      const areArraysEqual =
        JSON.stringify(newSegmentList) ===
        JSON.stringify(duplicateNewSegmentList);
      if (!areArraysEqual || clientReactivation || addressChange === true) {
        return (
          <>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p>Signature Specimen</p>
                <p>(jpg, png, jpeg, pdf)</p>
              </div>
              <input
                type="file"
                accept="image/*,.pdf"
                onChange={handleSignautureChange}
              />
            </div>
          </>
        );
      }
    };

    return (
      <div className="text-white ">
        <div className="text-white"> {renderBankProof()}</div>
        <div className="text-white">{renderFinancialProof()}</div>
        <div className="text-white"> {renderSign()}</div>
        <div className="text-white my-2">{renderPhoto()}</div>
      </div>
    );
  };

  const handleTabNext = (value) => {
    //---------------------------------------------------------------------------------
    setSelectedTab(+value);
  };
  const handleOtherTabNext = (value) => {
    if (optionNoChecked === false && optionYesChecked === false) {
      toast.error("Please Select Nominee Option");
    } else if (optionNoChecked === true) {
      setSelectedTab(value + 1);
    } else {
      setSelectedTab(+value);
    }
  };
  const handleDocumentTabprev = (value) => {
    if (optionNoChecked === true) {
      setSelectedTab(value - 1);
    } else {
      setSelectedTab(+value);
    }
  };
  const handleNomineeTabNext = (value) => {
    // Check if at least one nominee object in the array has all fields filled
    const isAtLeastOneNomineeValid = nominee.some(
      (n) =>
        n.name &&
        n.email &&
        n.contactNumber &&
        n.dateOfBirth &&
        n.relation &&
        n.addressDto.location &&
        n.addressDto.street &&
        n.addressDto.district &&
        n.addressDto.pincode &&
        n.addressDto.state &&
        n.addressDto.country &&
        n.sharePercentage &&
        n.proofOfAddress &&
        n.poiReferenceNumber &&
        n.typeOfDocument
    );

    if (isAtLeastOneNomineeValid) {
      const sumOfShared = nominee.reduce(
        (sum, { sharePercentage }) => sum + +sharePercentage,
        0
      );

      if (sumOfShared !== 100) {
        toast.error(
          `Shared percentage ${sumOfShared}. Please make sure the sum of shared percentage between nominees is 100.`
        );
        return;
      }

      setSelectedTab(+value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        nominee: nominee,
      }));
    } else {
      toast.error("Please fill all fields");
    }
  };

  const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
  if (storedResponse) {
    const data = JSON.parse(sessionStorage.getItem("apiResponse2"));
    const { name, clientCode, accountStatus, ckycNumber,dateOfBirth } = storedResponse;
    if (!data && accountStatus === "Disabled") {
      handleDigi();
      // <BrokeragePlan/>
    }
    return (
      <>
        {/* {accountStatus === "Disabled" ? <DigiLocker /> : ""} */}
        <div className="flex max-[500px]:flex-wrap px-2 bg-lightGrey text-white justify-between items-center w-full py-4 rounded-t-xl">
          <div className="flex max-[500px]:w-1/2 flex-col text-black">
            <p className="">Name</p>
            <span className="py-2 font-bold">{name}</span>
          </div>
          <div className="flex flex-col text-black max-[500px]:w-1/2">
            <p className="">Client Id</p>
            <span className="py-2 font-bold">{clientCode ?? ""}</span>
          </div>
          <div className="flex flex-col text-black max-[500px]:w-1/2">
            <p className="">Status</p>
            <span className="py-2 font-bold">{accountStatus ?? ""}</span>
          </div>
          <div className="flex flex-col text-black max-[500px]:w-1/2">
            <p className="">CKYC NO.</p>
            <span className="py-2 font-bold">{ckycNumber ?? ""}</span>
          </div>
          {/* {props.response.rekycStatus === "Disabled" ? <DigiLocker /> : ""} */}
        </div>
        <Tabs
          aria-label="Vertical tabs"
          orientation="vertical"
          variant="outlined"
          sx={{ minWidth: 300, minHeight: 160 }}
          // onChange={handleTabChange}
          value={selectedTab}
        >
          <TabList
            sx={{
              backgroundColor: "#C30000",
              opacity: "90%",
              borderRadius: "16px 0px 0px 16px",
              "@media (max-width: 780px)": {
                display: "none",
                // backgroundColor: "#071945",
              },
            }}
          >
            <Tab sx={{ padding: "16px" }}>
              <b className="text-black">Personal</b>
            </Tab>
            <Tab sx={{ padding: "16px" }}>
              <b className="text-black">Bank</b>
            </Tab>
            <Tab sx={{ padding: "16px" }}>
              <b className="text-black">Other</b>
            </Tab>
            <Tab sx={{ padding: "16px" }}>
              <b className="text-black">Nominee</b>
            </Tab>
            <Tab sx={{ padding: "16px" }}>
              <b className="text-black">Documents</b>
            </Tab>
            {/* <Tab sx={{ padding: "16px" }}>
              <b className="text-black">Upload Photo</b>
            </Tab> */}
          </TabList>
          <TabPanel
            value={0}
            sx={{
              backgroundColor: "#C30000",
              borderRadius: "0px 16px 16px 0px",
              "@media (max-width: 780px)": {
                // display: 'none',
                backgroundColor: "#071945",
              },
            }}
          >
            {renderPersonal()}
            <div className="flex justify-end">
              <Button
                sx={{
                  border: "1px solid white",
                  backgroundColor: "#071945",
                  borderRadius: "24px",
                  color: "white",
                  padding: "8px 12px",
                  // "&:hover": {
                  //   backgroundColor: "#071945",
                  "@media (max-width: 780px)": {
                    // display: 'none',
                    backgroundColor: "#C30000",
                    border: "1px solid white",
                    "&:hover": {},
                  },
                  // },
                }}
                onClick={() => handleTabNext(1)}
              >
                Next
              </Button>
            </div>
          </TabPanel>
          <TabPanel
            value={1}
            sx={{
              backgroundColor: "#C30000",
              borderRadius: "0px 0px 12px 0px",
              "@media (max-width: 780px)": {
                // display: 'none',
                backgroundColor: "#071945",
              },
              // overflow: "scroll",
            }}
          >
            <div className=" px-2 justify-start max-[780px:]bg-navy ">
              {renderBank()}
            </div>

            <div className="flex justify-between ">
              <Button
                sx={{
                  border: "1px solid white",
                  backgroundColor: "#071945",
                  borderRadius: "24px",
                  color: "white",
                  "&:hover": {
                    color: "white",
                  },
                  "@media (max-width: 780px)": {
                    // display: 'none',
                    backgroundColor: "#C30000",
                  },
                }}
                onClick={() => handleTabNext(0)}
              >
                Previous
              </Button>
              <Button
                sx={{
                  // backgroundColor: "#071945",
                  border: "1px solid white",
                  backgroundColor: "#071945",
                  borderRadius: "24px",
                  color: "white",
                  padding: "8px 12px",
                  "&:hover": {},
                  "@media (max-width: 780px)": {
                    // display: 'none',
                    backgroundColor: "#C30000",
                    border: "1px solid white",
                  },
                }}
                onClick={() => handleTabNext(2)}
              >
                Next
              </Button>
            </div>
          </TabPanel>
          <TabPanel
            value={2}
            sx={{
              backgroundColor: "#C30000",
              borderRadius: "0px 16px 16px 0px",
              "@media (max-width: 780px)": {
                // display: 'none',
                backgroundColor: "#071945",
              },
            }}
          >
            {renderOthers()}
            <div className="flex justify-between">
              <Button
                sx={{
                  border: "1px solid white",
                  backgroundColor: "#071945",
                  borderRadius: "24px",
                  color: "white",
                  "&:hover": {
                    color: "white",
                  },
                  "@media (max-width: 780px)": {
                    // display: 'none',
                    backgroundColor: "#C30000",
                    border: "1px solid white",
                  },
                }}
                onClick={() => handleTabNext(1)}
              >
                Previous
              </Button>
              <Button
                sx={{
                  border: "1px solid white",
                  backgroundColor: "#071945",
                  borderRadius: "24px",
                  color: "white",
                  padding: "8px 12px",
                  "&:hover": {},
                  "@media (max-width: 780px)": {
                    // display: 'none',
                    backgroundColor: "#C30000",
                    border: "1px solid white",
                  },
                }}
                onClick={() => handleOtherTabNext(3)}
              >
                Next
              </Button>
            </div>
          </TabPanel>
          <TabPanel
            value={3}
            sx={{
              backgroundColor: "#C30000",
              borderRadius: "0px 0px 12px 0px",
              "@media (max-width: 780px)": {
                // display: 'none',
                backgroundColor: "#071945",
              },
            }}
          >
            {renderNominee()}
          </TabPanel>
          <TabPanel
            value={4}
            sx={{
              backgroundColor: "#C30000",
              borderRadius: "0px 0px 12px 0px",
              "@media (max-width: 780px)": {
                backgroundColor: "#071945",
              },
            }}
          >
            <div>
              <p className="text-start text-2xl text-white py-4 font-semibold">
                Upload Documents
              </p>
              <hr className="text-white" />
              <div className="p-3">{renderDocuments()}</div>
            </div>
            <div className="flex justify-between ">
              <div>
                <Button
                  sx={{
                    border: "1px solid white",
                    backgroundColor: "#071945",
                    borderRadius: "24px",
                    color: "white",
                    "&:hover": {
                      color: "white",
                    },
                    "@media (max-width: 780px)": {
                      // display: 'none',
                      backgroundColor: "#C30000",
                      border: "1px solid white",
                    },
                  }}
                  onClick={() => handleDocumentTabprev(3)}
                >
                  Previous
                </Button>
              </div>
              <div>
                <button
                  className="border p-4 rounded-xl m-4 sm:bg-red md:bg-navy text-white"
                  onClick={handlePostData}
                >
                  Submit
                </button>
                {showComponent && <ThankYou />}
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel
            value={5}
            sx={{
              backgroundColor: "#C30000",
              borderRadius: "0px 0px 12px 0px",
              "@media (max-width: 780px)": {
                backgroundColor: "#071945",
              },
            }}
          >
          
            <Button
              sx={{
                border: "1px solid white",
                borderRadius: "24px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#071945",
                  color: "white",
                },
              }}
              onClick={() => handleDocumentTabprev(3)}
            >
              Previous
            </Button>
          </TabPanel> */}
          <ToastContainer />
        </Tabs>
      </>
    );
  }
}